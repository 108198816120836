<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
    <left-list :leftIndex="'1'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="survey">
        <div class="title">
          <span>数据概况</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      value1: "",
      value2: "",
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 1000;
        },
      },
      tableData:[],
      weekTotaloney:null
    };
  },
  created() {
    // console.log(JSON.parse(localStorage.getItem("userinfo")));
    this.getInfo();
  },
  methods: {
    getInfo(){
      this.$get('count/home_page',{}).then(res =>{
        this.tableData = res.data.data;
      })
    },
    moneyChange(e){
      console.log(e);
      this.$get('count/choice_date',{
        start_time:e[0],
        end_time:e[1]
      }).then(res =>{
        this.weekTotaloney = res.data.data.total_money;
      })
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;

  &::-webkit-scrollbar {
    display: none;
  }

  .txt-red {
    color: red;
  }

  .title {
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .turnover {
    padding: 15px 30px;

    .Totalturnover {
      margin-left: 30px;
    }

    .date {
      margin-top: 30px;

      .month {
        margin-bottom: 30px;
      }
    }
  }

  .survey {
    padding: 15px 30px;
    margin-bottom: 20px;

    .list {
      padding: 0 20px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .one {
        width: calc(50% / 2);
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
        padding-top: 20px;
        font-size: 20px;

        .list-title {
          font-size: 24px;
          font-weight: 800;
        }

        .footer-title {
          font-size: 18px;
        }

        .list-title,
        .number,
        .footer-title {
          margin-bottom: 40px;
        }
      }
    }
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 20px solid #f2f2f2;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}
</style>
